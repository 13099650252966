import HolidayUniIcon from 'components/Logo/HolidayUniIcon'
import { SVGProps } from 'components/Logo/UniIcon'
import styled from 'styled-components'

function Logo({ onClick }: { onClick?: () => void }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      onClick={onClick}
      cursor="pointer"
    >
      <path
        fill="#6F3799"
        d="M15.19,6.69a4.81,4.81,0,0,0-4.68,3.72H0V12.6H10.51a4.81,4.81,0,1,0,4.68-5.91Zm0,7.55a2.74,2.74,0,1,1,2.74-2.73A2.74,2.74,0,0,1,15.19,14.24Z"
      />
      <path
        fill="#6F3799"
        d="M8.69,5.68H7.52a.25.25,0,0,1,0,.08,1.32,1.32,0,1,1-2.63,0,.25.25,0,0,1,0-.08H3.79s0,.06,0,.08a1.32,1.32,0,0,1-2.64,0s0,0,0-.08H0v2.8H8.69Z"
      />
      <path fill="#6F3799" d="M14.63,10.38v2.25a1.13,1.13,0,0,0,0-2.25Z" />
    </svg>
  )
}

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
`

type NavIconProps = SVGProps & {
  clickable?: boolean
  onClick?: () => void
}

export const NavIcon = ({ clickable, onClick, ...props }: NavIconProps) => (
  <Container clickable={clickable}>
    {HolidayUniIcon(props) !== null ? <HolidayUniIcon {...props} /> : <Logo onClick={onClick} />}
  </Container>
)
